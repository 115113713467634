import React, { useContext, useState } from 'react';
import { Container, Form, Button, Alert } from 'react-bootstrap';
import './Auth.css';
import axios from 'axios';
import { PublicContext } from '../../context/public-context';

const Auth = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  let { baseURL} = useContext(PublicContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    const data={
      email:username,
      password:password
    }
    axios.post(`${baseURL}/login`,data)
    .then((response) => {
      if(response.data.result=="true"){
        onLogin(response.data.access_token);
      }
      else{
        setError('Invalid username or password');
      }
    })
    .catch((error)=>console.log(error)) 

    // if (username === 'mazen' && password === '123456') {
    //   onLogin();
    // } else {
    //   setError('Invalid username or password');
    // }
    
  };

  return (
    <Container className="auth-container border rounded p-4 mt-5 mb-5">
      <h2 className="text-center mb-4">Login</h2>
      {error && <Alert variant="danger">{error}</Alert>}
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formUsername">
          <Form.Label>Email</Form.Label>
          <Form.Control 
            type="Email" 
            placeholder="Enter username" 
            value={username} 
            onChange={(e) => setUsername(e.target.value)} 
          />
        </Form.Group>

        <Form.Group controlId="formPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control 
            type="password" 
            placeholder="Password" 
            value={password} 
            onChange={(e) => setPassword(e.target.value)} 
          />
        </Form.Group>

        <Button variant="primary" className='my-2' type="submit" block>
          Login
        </Button>
      </Form>
    </Container>
  );
};

export default Auth;
