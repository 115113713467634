import React, { useContext, useState } from 'react';
import { Alert, Button } from 'react-bootstrap';
import Sidebar from '../../Sidebar/Sidebar';
import { PublicContext } from '../../../context/public-context';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';


const AddCategory = () => {

    let { baseURL,token} = useContext(PublicContext);
    const navigate=useNavigate();
  
    const [formData, setFormData] = useState({
        name: '',
        image: null,
    });

    const [alert, setAlert] = useState({ show: false, variant: '', message: '' });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleFileChange = (e) => {
        setFormData({ ...formData, image: e.target.files[0] });
    };

    const handleSubmit = (e) => {
        const updatedFormData = new FormData();
        updatedFormData.append('name', formData.name);
        updatedFormData.append('name_en', formData.name_en);
        updatedFormData.append('image', formData.image);
        e.preventDefault();
        axios.post(`${baseURL}/categories`,updatedFormData,{ headers: {'Authorization': `Bearer ${token}`}})
        .then((response) => {
             navigate('/thecategories');
        })
        .catch((error)=>console.log(error)) 
    };

    return (
        <div style={{marginBottom:'10px'}}>
            <center style={{margin:'10px'}}>
                <Sidebar /><br/><br/>
                <Button variant='primary' href='/dashboard'>Back To Dashboard</Button>
            </center>
            {alert.show && <Alert variant={alert.variant}>{alert.message}</Alert>}
            <form className="register-form" id='elfed' onSubmit={handleSubmit}>
                <p className="register-title">Add Category</p>
                <p className="register-message">
                    Fill data to add the category
                </p>
                <label className="register-label">
                    <input
                        className="register-input"
                        type="text"
                        name="name"
                        placeholder=""
                        value={formData.name}
                        onChange={handleChange}
                        required
                    />
                    <span>Category Name </span>
                </label>
                <label className="register-label">
                    <input
                        className="register-input"
                        type="text"
                        name="name_en"
                        placeholder=""
                        value={formData.name_en}
                        onChange={handleChange}
                        required
                    />
                    <span>Category Name In English</span>
                </label>
                <label className="register-label">
                    <input
                        className="register-input"
                        type="file"
                        name="image"
                        onChange={handleFileChange}
                        required
                    />
                    <span>Upload Image</span>
                </label>
                <button className="register-submit" type="submit">Submit</button>
            </form>
        </div>
    );
}

export default AddCategory;
