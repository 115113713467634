import React, { useContext, useState } from 'react';
import { Container, Form, Button, Alert } from 'react-bootstrap';
import '../Auth.css';
import axios from 'axios';
import { PublicContext } from '../../../context/public-context';
import { useNavigate } from 'react-router-dom';

const AddNewLink = () => {
  const [form, setForm] = useState({title:'video'});
  const [error, setError] = useState('');
  let { baseURL,token} = useContext(PublicContext);
  const navigate=useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
    axios.post(`${baseURL}/youtube-links`,form,{headers: {'Authorization': `Bearer ${token}`}})
    .then((response) => {
        navigate('/youtubeVideos');
    })
    .catch((error)=>console.log(error)) 
  };

  const handleChangeForm=(event)=>{
    const {name,value}=event.target
    setForm({
        ...form,
        [name]:value
    })
  }

  return (
    <Container className="auth-container border rounded p-4 mt-5 mb-5">
      <h2 className="text-center mb-4">Login</h2>
      {error && <Alert variant="danger">{error}</Alert>}
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formUsername">
          <Form.Label>Link</Form.Label>
          <Form.Control 
            type="text" 
            placeholder="Enter url" 
            name='url'
            onChange={handleChangeForm} 
          />
        </Form.Group>
        <Form.Group >
          <Form.Label>Description</Form.Label>
          <Form.Control 
            type="text" 
            placeholder="Enter description in arabic " 
            name='description'
            onChange={handleChangeForm} 
          />
        </Form.Group>
        <Form.Group >
          <Form.Label>Description in English</Form.Label>
          <Form.Control 
            type="text" 
            placeholder="Enter description in english " 
            name='description_en'
            onChange={handleChangeForm} 
          />
        </Form.Group>

        <Button variant="primary" type="submit" block>
          Add
        </Button>
      </Form>
    </Container>
  );
};

export default AddNewLink;
