import React, { useContext, useEffect, useState } from 'react'
import { PublicContext } from '../../context/public-context';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import callimg from "../../assets/imgs/phone-call.png";

import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Pagination } from 'swiper/modules';

export default function SocialMediaSection() {
     const [contactData, setContactData] = useState([]);
    let { baseURL,imgSrc} = useContext(PublicContext);

    const { t,i18n } = useTranslation()
    const fetchContactData = ()=> {
        axios.get(`${baseURL}/socialmedia`)
        .then(response =>{setContactData(response.data.data);})
        .catch(error => console.error('Error fetching images:', error));
    };

    useEffect(() => {
        fetchContactData();
    }, []);

  return (
    <div className='mx-5  d-flex justify-content-center'>
        <div className="row col-6">
        <h3 className="text-center">{t('social_media')}</h3>
        <Swiper 
        id='social_swiper'
        slidesPerView={4}
        spaceBetween={5}
        freeMode={true}
        pagination={{
          clickable: true,
        }}
        modules={[FreeMode, Pagination]}
        className="mySwiper5"
      >
            {contactData.length>0 &&contactData.map(contact=>(
            
            <SwiperSlide>
                <div className="unique-card2 m-2">
                  <a href={contact.link} target='blank'>
                    <div className="background-overlay"></div>
                    <div className="card-content carwid">
                        <div className="card-title d-flex align-content-center justify-content-center mb-2">
                        <img src={imgSrc+'/'+contact.icon}  style={{ width: '15%', height: '15%' }} /> 
                        </div>
                        <div className="card-description"> { i18n.language === 'ar' ? contact.name : contact.name_en}</div>
                    </div>
                  </a>
                </div>
            </SwiperSlide>
        ))}

      </Swiper>


    

        {/* <li><a href={contact.link}>{ i18n.language === 'ar' ? contact.name : contact.name_en}</a></li> */}

        </div>
    </div>
  )
}
