import React, { useState, useEffect, useContext } from 'react';
import { Container, Button, Alert, Table } from 'react-bootstrap';
import { PublicContext } from '../../context/public-context';
import axios from 'axios';
// import Sidebar from '../../Sidebar/Sidebar'; // Make sure to import your Sidebar component

const WebsiteSlider = () => {
  const [slider, setSlider] = useState([]);
  const [editingSliderId, setEditingSliderId] = useState(null);
  const [editedImage, setEditedImage] = useState({ name: '', image: '' });
  const [alert, setAlert] = useState({ show: false, message: '', variant: '' });
  let { baseURL,imgSrc, token} = useContext(PublicContext);

  useEffect(() => {
    getSlider();
  }, []);

  const getSlider = async () => {
    axios.get(`${baseURL}/artmin/websiteSlider`)
    .then((response) => setSlider(response.data.data))
    .catch((error)=>console.log(error)) 
  };

  const handleEditClick = (id, product) => {
    setEditingSliderId(id);
    setEditedImage(product);
  };



  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setEditedImage( file );
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSaveClick = async (id) => {
    const updatedFormData = new FormData();
    updatedFormData.append('type', 'websiteSlider');
    updatedFormData.append('name', 'name');
    updatedFormData.append('name_en', 'name_en');
    updatedFormData.append('description', 'description');
    updatedFormData.append('description_en', 'description_en');
    updatedFormData.append('img', editedImage);
    axios.post(`${baseURL}/artmin/update/${id}`,updatedFormData,{headers: {'Authorization': `Bearer ${token}`}})
    .then((response) => {
      window.location.reload();
      
    })
    .catch((error)=>console.log(error)) 
  };
  
  // setAlert({ show: true, message: error.message, variant: 'danger' });
  // setAlert({ show: true, message: 'Product updated successfully!', variant: 'success' });
  const handleDeleteClick = async (id) => {
    axios.get(`${baseURL}/artmin/destroy/${id}`,{ headers: {'Authorization': `Bearer ${token}`}})
    .then((response) => {
      window.location.reload();
    })
    .catch((error)=>console.log(error)) 
  };

  const addNewImage=(event)=>{
    // console.log(event.target.files[0]);
    const updatedFormData = new FormData();
    updatedFormData.append('type', 'websiteSlider');
    updatedFormData.append('name', 'name');
    updatedFormData.append('name_en', 'name_en');
    updatedFormData.append('description', 'description');
    updatedFormData.append('description_en', 'description_en');
    updatedFormData.append('file', event.target.files[0]);
    axios.post(`${baseURL}/artmin/store`,updatedFormData,{ headers: {'Authorization': `Bearer ${token}`}})
    .then((response) => {
      window.location.reload();
      
    })
    .catch((error)=>console.log(error))
  }

  return (
    <div>
      <Container style={{ marginBottom: '20px' }}>
        
        <center style={{ margin: '10px' }}>
          {/* <Sidebar /><br /><br /> */}
          <Button variant='primary' href='/dashboard'>Back To Dashboard</Button>
        </center>
        <h2 className="headline">Edit or Delete Country</h2>
        {alert.show && <Alert variant={alert.variant}>{alert.message}</Alert>}
        <Button className='my-3' onClick={() => document.getElementById('fileInput').click()}> Add Image </Button>
        <input type="file" id="fileInput" style={{ display: 'none' }} onChange={addNewImage}/>
        <Table bordered>
          <thead>
            <tr>
              <th className='col-8'>Image</th>
              <th className='col-4'>Actions</th>
            </tr>
          </thead>
          <tbody>
            {slider.length>0 && slider.map(slider => (
              <tr key={slider.id}>
                <td>
                  {editingSliderId === slider.id ? (
                    <input
                      type="file"
                      name="image"
                      onChange={handleFileChange}
                    />
                  ) : (
                    slider.is_image ==1 ? (
                      <img src={imgSrc+'/'+slider.img} alt={slider.name} style={{ width: '100px', height: '100px' }} />
                      ) : (
                          <video width="20" height="20" controls className='video-dash'>
                          <source src={imgSrc+'/'+slider.video} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                    )
                    
                  )}
                </td>
                <td>
                  <div className="d-flex justify-content-center">
                    {editingSliderId === slider.id ? (
                      <Button onClick={() => handleSaveClick(slider.id)}>Save</Button>
                    ) : (
                      <Button onClick={() => handleEditClick(slider.id, slider)}>Edit</Button>
                    )}
                    <Button variant="danger" className='mx-2' onClick={() => handleDeleteClick(slider.id)}>Delete</Button>

                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Container>
    </div>
  );
}

export default WebsiteSlider;
