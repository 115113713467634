import React, { useState, useEffect, useContext } from 'react';
import { Table, Container, Button, Alert } from 'react-bootstrap';
import Sidebar from '../Sidebar/Sidebar';
import { PublicContext } from '../../context/public-context';
import axios from 'axios';

const Partners = () => {
    const [partners, setpartners] = useState({});
    const [countries, setCountries] = useState(null);
    const [editingField, setEditingField] = useState('');
    const [editedImage, setEditedImage] = useState('');

    const [newData, setNewData] = useState({});
    const [alert, setAlert] = useState({ show: false, message: '', variant: '' });
    let { baseURL,token,imgSrc} = useContext(PublicContext);


    const fetchPartners = async () => {
        axios.get(`${baseURL}/partners`)
        .then(response =>{setpartners(response.data.data.data); console.log(response.data.data);})
        .catch(error => console.error('Error fetching images:', error));
    };

    const getcountries = async () => {
        axios.get(`${baseURL}/countries`)
        .then((response) => setCountries(response.data.data))
        .catch((error)=>console.log(error)) 
      };

    useEffect(() => {
        fetchPartners();
        getcountries();
    }, []);

    const handleEdit = async (contactData) => {
        setEditingField(contactData.id);
        setNewData(contactData);
    };

    const handleChange = (event) => {
        const {name,value}=event.target
        setNewData({
            ...newData,
            [name]:value
        });
    };

    const handleSave = async () => {
        const updatedFormData = new FormData();
    updatedFormData.append('name', newData.name);
    updatedFormData.append('name_en', newData.name_en);
    updatedFormData.append('country_id', newData.country_id);
    updatedFormData.append('type', newData.type);
    updatedFormData.append('logo', editedImage);
        axios.post(`${baseURL}/partners/${editingField}`,updatedFormData,{ headers: {'Authorization': `Bearer ${token}`}})
        .then(response =>{window.location.reload();})
        .catch(error => console.error('Error fetching images:', error));
     
        // setAlert({ show: true, message: 'Contact data updated successfully', variant: 'success' });
        // setEditingField('');
        // setNewData('');
        // setAlert({ show: true, message: error.message, variant: 'danger' });
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
          const reader = new FileReader();
          reader.onloadend = () => {
            setEditedImage( file );
          };
          reader.readAsDataURL(file);
        }
      };


      const handleDeleteClick = async (id) => {
        axios.get(`${baseURL}/partners/destroy/${id}`,{ headers: {'Authorization': `Bearer ${token}`}})
        .then((response) => {
          window.location.reload();
        })
        .catch((error)=>console.log(error)) 
      };

    return (
        <Container className="my-5">
                   <center style={{ margin: '10px' }}>
        {/* <Sidebar /><br /><br /> */}
        <Button variant='primary' href='/dashboard'>Back To Dashboard</Button>
      </center>
            <h2 className="text-center mb-4">Manage Contact Information</h2>
            {alert.show && <Alert variant={alert.variant} onClose={() => setAlert({ show: false })} dismissible>{alert.message}</Alert>}
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Name_en</th>
                        <th>Country</th>
                        <th>Type</th>
                        <th>Logo</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {partners.length>0 && partners.map(contact => (
                        <tr key={contact.id}>
                            <td>
                                {editingField === contact.id ? (
                                        <input type="text" value={newData.name} name='name' onChange={handleChange} />
                                    ) : (
                                        contact.name
                                    )}
                            </td>
                            <td>
                                {editingField === contact.id ? (
                                        <input type="text" value={newData.name_en} name='name_en' onChange={handleChange} />
                                    ) : (
                                        contact.name_en
                                    )}
                            </td>
                            
                            <td>
                                {editingField === contact.id ? (
                                        <select name="country_id" value={newData.country_id} onChange={handleChange}>
                                            {countries && countries.map(e=>(
                                                <option value={e.id} selected={contact.country?.id === e.id ? true : false} >{e.name_en}</option>

                                            ))}
                                        </select>
                                        // <input type="text" value={newData.id} name='id' onChange={handleChange} />
                                    ) : (
                                        contact.country?.name
                                    )}
                            </td>
                            <td>
                                {editingField === contact.id ? (
                                        <select value={newData.type} name='type' onChange={handleChange}>
                                            <option selected={contact.type === "all" ? true : false} value="all">All Type</option>
                                            <option selected={contact.type === "reseller" ? true : false} value="reseller">Reseller</option>
                                        </select>
                                    ) : (
                                        contact.type
                                    )}
                            </td>
                            <td>
                                {editingField === contact.id ? (
                                    <input
                                    type="file"
                                    name="logo"
                                    onChange={handleFileChange}
                                    />
                                ) : (
                                    <img src={imgSrc+'/'+contact.logo} alt={contact.logo} style={{ width: '50px' }} />
                                )}
                            </td>
                            <td>
                                <div className="d-flex justify-content-center">
                                    {editingField === contact.id ? (
                                        <Button variant="success" onClick={handleSave}>Save</Button>
                                    ) : (
                                        <Button variant="primary" onClick={() => handleEdit(contact)}>Edit</Button>
                                        
                                    )}
                                        <Button variant="danger" className='mx-2' onClick={() => handleDeleteClick(contact.id)}>Delete</Button>
                                </div>


                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </Container>
    );
};

export default Partners;
