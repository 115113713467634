import React, { useContext, useEffect, useState } from 'react';
import './VideoSection.css';
import { PublicContext } from '../../context/public-context';
import axios from 'axios';

const VideoSection = () => {
  // Array of YouTube video IDs
  // const videos = [
  //   'dQw4w9WgXcQ', // Replace with your own video IDs
  //   'L_jWHffIx5E',
  //   'dQw4w9WgXcQ', // Replace with your own video IDs
  //   '3JZ_D3ELwOQ',
  //   'L_jWHffIx5E',
  //   'L_jWHffIx5E',
  //   'dQw4w9WgXcQ', // Replace with your own video IDs
  // ];
  const [videos, setVideos] = useState([]);
  let { baseURL} = useContext(PublicContext);
  useEffect(() => {
    getVideos();
  }, []);

  const getVideos = async () => {
    axios.get(`${baseURL}/youtube-links`)
    .then((response) => setVideos(response.data.data))
    .catch((error)=>console.log(error)) 
  };

  return (
    <div className="row m-5">
      {videos.map((video, index) => (
        <div key={index} className="col-md-4 col-sm-10 g-3 ">
          <iframe
            width="100%"
            height="250px"
            src={`https://www.youtube.com/embed/${video.url}`}
            title={`YouTube video player ${index}`}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
          <p className="video-description"></p>
        </div>
      ))}
    </div>
  );
};

export default VideoSection;
