import React, { useContext, useEffect, useState } from 'react';
import { Alert, Button } from 'react-bootstrap';
import Sidebar from '../Sidebar/Sidebar';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { PublicContext } from '../../context/public-context';


const AddPartner = () => {

    let { baseURL,token} = useContext(PublicContext);
    const navigate=useNavigate();
    
    const [formData, setFormData] = useState({
        name: '',
        image: null,
    });
    const [continent, setContinent] = useState(null)
    const getContinent = async () => {
        axios.get(`${baseURL}/countries`)
        .then((response) => setContinent(response.data.data))
        .catch((error)=>console.log(error)) 
      };
      useEffect(() => {
        getContinent();
      }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };
    const handleFileChange = (e) => {
        setFormData({ ...formData, logo: e.target.files[0] });
    };
 

    const handleSubmit = (e) => {
        const updatedFormData = new FormData();
        updatedFormData.append('name', formData.name);
        updatedFormData.append('name_en', formData.name_en);
        updatedFormData.append('country_id', formData.country_id);
        updatedFormData.append('type', formData.type);
        updatedFormData.append('logo', formData.logo);
        e.preventDefault();
        axios.post(`${baseURL}/partners`,updatedFormData,{ headers: {'Authorization': `Bearer ${token}`}})
        .then((response) => {
             navigate('/partners');
        })
        .catch((error)=>console.log(error)) 
    };

    return (
        <div style={{marginBottom:'10px'}}>
            <center style={{margin:'10px'}}>
                {/* <Sidebar /><br/><br/> */}
                <Button variant='primary' href='/dashboard'>Back To Dashboard</Button>
            </center>
            {alert.show && <Alert variant={alert.variant}>{alert.message}</Alert>}
            <form className="register-form" id='elfed' onSubmit={handleSubmit}>
                <p className="register-title">Add Partner</p>
                <p className="register-message">
                    Fill data to add the Partner
                </p>
                <label className="register-label">
                    <input
                        className="register-input"
                        type="text"
                        name="name"
                        placeholder=""
                        value={formData.name}
                        onChange={handleChange}
                        required
                    />
                    <span>Partner Name </span>
                </label>
                <label className="register-label">
                    <input
                        className="register-input"
                        type="text"
                        name="name_en"
                        placeholder=""
                        value={formData.name_en}
                        onChange={handleChange}
                        required
                    />
                    <span>Partner Name In English</span>
                </label>
                <label className="register-label">
                    <select name="country_id" className='register-input'  value={formData.country_id} onChange={handleChange}>
                        <option >select country</option>
                    {continent && continent.map(e=>(
                        <option className="register-input" value={e.id}>{e.name_en}</option>
                    ))}
                    </select>
                    <span>Select Country</span>
                </label>
                <label className="register-label">
                    <select name="type" className='register-input'  value={formData.type} onChange={handleChange}>
                        <option >select type</option>
                        <option value="all">All Type</option>
                        <option value="reseller">Reseller</option>
                    </select>
                    <span>Select Type</span>
                </label>
                <label className="register-label">
                    <input
                        className="register-input"
                        type="file"
                        name="image"
                        onChange={handleFileChange}
                        required
                    />
                    <span>Upload Logo</span>
                </label>

                <button className="register-submit" type="submit">Submit</button>
            </form>
        </div>
    );
}

export default AddPartner;
