import React, { useContext, useEffect, useState } from 'react';
import { Alert, Button, Container, Table } from 'react-bootstrap';
import Sidebar from '../../Sidebar/Sidebar';
import { PublicContext } from '../../../context/public-context';
import axios from 'axios';

const ProductVisits = () => {
  const [statistics, setStatistics] = useState(null);
  const [alert, setAlert] = useState({ show: false, variant: '', message: '' });

  const { baseURL, token } = useContext(PublicContext);

  const fetchStatistics = () => {
    axios.get(`${baseURL}/product-visits`, { headers: { 'Authorization': `Bearer ${token}` } })
      .then(response => {
        if (response.data.result === 'true') {
          setStatistics(response.data.data);
        } else {
          setAlert({ show: true, variant: 'danger', message: 'Failed to fetch statistics' });
        }
      })
      .catch(error => {
        console.error('Error fetching product visit statistics:', error);
        setAlert({ show: true, variant: 'danger', message: 'Error fetching product visit statistics' });
      });
  };

  useEffect(() => {
    fetchStatistics();
  }, []);

  if (!statistics) {
    return <p>Loading...</p>;
  }

  return (
    <Container style={{ marginBottom: '20px' }}>
      <center style={{ margin: '10px' }}>
        <Sidebar /><br /><br />
        <Button variant='primary' href='/dashboard'>Back To Dashboard</Button>
      </center>
      <h2 className="headline">Product Visit Statistics</h2>
      {alert.show && <Alert variant={alert.variant}>{alert.message}</Alert>}

      <h3>Total Visits</h3>
      <p>{statistics.total_visits}</p>

      <h3>Visits by Country</h3>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Country</th>
            <th>Total Visits</th>
          </tr>
        </thead>
        <tbody>
          {statistics.visits_by_country && statistics.visits_by_country.map((item, index) => (
            <tr key={index}>
              <td>{item.country}</td>
              <td>{item.total}</td>
            </tr>
          ))}
        </tbody>
      </Table>

      <h3>Top 20 Most Visited Products</h3>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Product Name</th>
            <th>Product Name english</th>
            <th>Total Visits</th>
          </tr>
        </thead>
        <tbody>
          {statistics.top_visited_products && statistics.top_visited_products.map((item, index) => (
            <tr key={index}>
              <td>{item.product.name}</td>
              <td>{item.product.name_en}</td>
              <td>{item.total}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default ProductVisits;
