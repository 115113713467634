import React, { useState } from "react";
import { Button, Container, Form, InputGroup, Row, Col } from "react-bootstrap";
import './mail.css';
import { useTranslation } from "react-i18next";

const Mail = () => {
  const { i18n, t } = useTranslation();
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch("https://hook.mhabashy.com/api/subscribes", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: email, // Use the email as the name
          type: "email", // Since you're subscribing with email
        }),
      });
  
      if (response.ok) {
        const data = await response.json();
        if (data.result === "true") {
          setMessage(i18n.language === 'ar' ? data.message : data.message_en);
          setEmail(""); // Clear the input field
        } else {
          // This part will handle the response in case of failure
          setMessage(i18n.language === 'ar' ? "فشل الاشتراك. حاول مرة اخرى" : "Subscription failed. Please try again.");
        }
      } else {
        const errorData = await response.json();
        if (errorData.result === "false" && errorData.message.name) {
          const errorMessage = errorData.message.name[0];
          setMessage(i18n.language === 'ar' ? `البريد الإلكتروني موجود بالفعل. ` : `Email is already taken.`);
        } else {
          setMessage(i18n.language === 'ar' ? "فشل الاشتراك. حاول مرة اخرى" : "Subscription failed. Please try again.");
        }
      }
    } catch (error) {
      setMessage(i18n.language === 'ar' ? "فشل الاشتراك. حاول مرة اخرى" : "Subscription failed. Please try again.");
    }
  };
  

  return (
    <div className="Mailsec">
      <Container>
        <Row className="text-center mb-3">
          <Col>
            <h3>{t('Join Our Mailing List')}</h3>
          </Col>
        </Row>
        <Row className="justify-content-center px-3">
          <Col xs={12} md={8} lg={6} className="d-flex justify-content-center">
            <div className="rounded-lg bg-light p-4 w-100 con">
              <Form onSubmit={handleSubmit}>
                <InputGroup>
                  <Form.Control
                    type="email"
                    placeholder={t('Your Mail')}
                    aria-label="Recipient's email"
                    aria-describedby="basic-addon2"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  <Button type="submit" variant="primary" className="rounded-end">
                    {t('Join')}
                  </Button>
                </InputGroup>
              </Form>
              {message && <p className="mt-3 text-center">{message}</p>}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Mail;
