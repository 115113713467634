import React, { useContext, useEffect, useState } from 'react';
import { Button, Alert } from 'react-bootstrap';
import Sidebar from '../../Sidebar/Sidebar'; // Make sure to import your Sidebar component
import { useTranslation } from 'react-i18next';
import { PublicContext } from '../../../context/public-context';
import axios from 'axios';


const OurGoalSection = () => {
  const { t, i18n } = useTranslation();
  const [formData, setFormData] = useState({ name: '', name_en: '', description: '', description_en:'', image: '' });
  const [alert, setAlert] = useState({ show: false, message: '', variant: '' });
  let { baseURL,token} = useContext(PublicContext);

  useEffect(() => {
    getSection();
   }, []);
  

  const getSection=()=>{
    axios.get(`${baseURL}/static_page/2`)
    .then((response) => setFormData(response.data.data))
    .catch((error)=>console.log(error)) 
  } 

  const updateSection=(updatedFormData)=>{
    axios.post(`${baseURL}/static_page/update/2`,updatedFormData,{headers: {'Authorization': `Bearer ${token}`}})
    .then((response) => {
      setFormData(response.data.data)
      setAlert({ show: true, message: 'Section updated successfully!', variant: 'success' });

    })
    .catch((error)=>{console.log(error)
     setAlert({ show: true, message: error.message, variant: 'danger' });
    }) 
  } 

  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      console.log(file);
      reader.onloadend = () => {
        setFormData({ ...formData, image: file });
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const updatedFormData = new FormData();
    updatedFormData.append('name', formData.name);
    updatedFormData.append('name_en', formData.name_en);
    updatedFormData.append('description', formData.description);
    updatedFormData.append('description_en', formData.description_en);
    console.log(formData);
    if (formData.image) {
      updatedFormData.append('img', formData.image);
    }
    console.log(updateSection);
    updateSection(updatedFormData)

  }
 

  return (
    <div style={{ marginBottom: '10px' }}>
      <center style={{ margin: '10px' }}>
        <Sidebar /><br /><br />
        <Button variant='primary' href='/dashboard'>{t('back_To _dashboard')}</Button>
      </center>
      {alert.show && <Alert variant={alert.variant}>{alert.message}</Alert>}
      <form className="register-form" id='elfed' onSubmit={handleSubmit} enctype="multipart/form-data"> 
        <p className="register-title">{t('our_goal_section')}</p>


        <label className="register-label">
          <input className="register-input" type="text" name="name_en" value={formData.name_en} onChange={handleChange} required/>
          <span>{t('name_in_english')}</span>
        </label>

        <label className="register-label">
          <input className="register-input" type="text" name="name" value={formData.name} onChange={handleChange} required/>
          <span>{t('name_in_arabic')}</span>
        </label>
        
        <label className="register-label">
          <textarea className="register-input" type="text" name="description_en" value={formData.description_en} onChange={handleChange} required/>
          <span>{t('description_in_english')}</span>
        </label>

        <label className="register-label">
          <textarea className="register-input" type="text" name="description" value={formData.description} onChange={handleChange} required/>
          <span>{t('description_in_arabic')}</span>
        </label>
        

        <label className="register-label">
          <input className="register-input" type="file" name="image"  onChange={handleFileChange} />
          <span>{t('image')}</span>
        </label>
        <img src={`https://hook.mhabashy.com/uploads/${formData.img}`} alt="Current" style={{ maxWidth: '100%', height: '300px', marginTop: '10px' }} />
        <button className="register-submit" type="submit">{t('submit')}</button>
      </form>
    </div>
  );
}

export default OurGoalSection;
