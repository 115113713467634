import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import "./Nav.css";
import { Col, Row } from "react-bootstrap";
import slog from "../../assets/imgs/h-colored.png";
import headset from "../../assets/imgs/headset.png";
import mouse from "../../assets/imgs/mouse.png";
import keyboard from "../../assets/imgs/keyboard.png";
import Access from "../../assets/imgs/access.png";
import chair from '../../assets/imgs/chairs.png';

import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { PublicContext } from "../../context/public-context";
const Navy = () => {

  const { t, i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  let { baseURL,imgSrc} = useContext(PublicContext);
  const [categories, setCategories] = useState([]);
  const navigate=useNavigate();
  const handleImageError = (e) => {
    e.target.src = `${process.env.PUBLIC_URL}/hook.jpg`;
  };
  const fetchCategories = () => {
    axios.get(`${baseURL}/categories`)
    .then(response =>setCategories(response.data.data) )
    .catch(error => console.error('Error fetching categories:', error));
  };

  useEffect(() => {
    fetchCategories();
  }, []);


  return (
    <>
      <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary">
        <Container>
          <Navbar.Brand href="/">
            <img src={slog} width={"150px"} />
          </Navbar.Brand>
          <div className="vl d-none d-md-block"></div>

          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav></Nav>
            <Nav className="links ms-auto ">
              <Nav.Link href="/">{t('home')}</Nav.Link>
             
              <NavDropdown title={t('products')} id="collapsible-nav-dropdown">
                <Row>
                {categories.slice(0, 6).map((category, index) => (
                  <Col sm={6} md={4} key={index} className="">
                    <NavDropdown.Item href={`/products/${category.id}`}>
                      <div className="navprod1 d-flex justify-content-center align-items-center">
                        <div className="navprodimg">
                          <img src={imgSrc+'/'+category.img} alt={category.name} onError={handleImageError} style={{width:'100px'}}/>
                        </div>
                        <div className="mx-1">
                          <h4> {i18n.language === 'ar' ? category.name : category.name_en}</h4>
                        </div>
                      </div>
                    </NavDropdown.Item>
                  </Col>
                ))}
                </Row>

                <NavDropdown.Divider />
                <NavDropdown.Item href="/cate">
                      <div className="navprod1">
                        <div className="navprodimg">
                          <img src='https://png.pngtree.com/png-vector/20220818/ourmid/pngtree-computer-accessories-vector-or-png-png-image_6115350.png' width={'75px'} />
                        </div>
                        <div className="navprodtitles">
                          <h5>{t('All Categories')}</h5>
            
                        </div>
                      </div>
                    </NavDropdown.Item>   
              </NavDropdown>
              <Nav.Link href="about">{t('about_us')}</Nav.Link>
              <Nav.Link eventKey={2} href="/contact">{t('contact_us')}</Nav.Link>

              {/* <NavDropdown title={t('language')} id="collapsible-nav-dropdown-lang" className="lang-dropdown">
                <NavDropdown.Item className="lang-container">
                    <div onClick={() => changeLanguage('en')} className="lang-option">English</div>
                </NavDropdown.Item>
                <NavDropdown.Item className="lang-container">
                  <div onClick={() => changeLanguage('ar')} className="lang-option">العربية</div>
                </NavDropdown.Item>
            </NavDropdown> */}

{i18n.language === 'ar' &&<Nav.Link className="lang-container">
                    <div onClick={() => changeLanguage('en')} className="lang-option">English</div>
            </Nav.Link>}

            {i18n.language === 'en' && <Nav.Link className="lang-container">
                  <div onClick={() => changeLanguage('ar')} className="lang-option">العربية</div>
            </Nav.Link>}

            </Nav>
          </Navbar.Collapse>

          {/* <h1></h1>
          <p>{t('description')}</p> */}
        </Container>
      </Navbar>
    </>
  );
};

export default Navy;
