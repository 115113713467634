import React, { useState, useEffect, useContext } from 'react';
import { Table, Container, Button, Alert } from 'react-bootstrap';
import Sidebar from '../../Dash/Sidebar/Sidebar';
import { PublicContext } from '../../context/public-context';
import axios from 'axios';

const ContactUSManage = () => {
    const [contactData, setContactData] = useState({});
    const [editingField, setEditingField] = useState('');
    const [editedImage, setEditedImage] = useState('');

    const [newData, setNewData] = useState({});
    const [alert, setAlert] = useState({ show: false, message: '', variant: '' });
    let { baseURL,token,imgSrc} = useContext(PublicContext);


    const fetchContactData = async () => {
        axios.get(`${baseURL}/socialmedia`)
        .then(response =>{setContactData(response.data.data); console.log(response.data.data);})
        .catch(error => console.error('Error fetching images:', error));
    };

    useEffect(() => {
        fetchContactData();
    }, []);

    const handleEdit = async (contactData) => {
        setEditingField(contactData.id);
        setNewData(contactData);
    };

    const handleChange = (event) => {
        const {name,value}=event.target
        setNewData({
            ...newData,
            [name]:value
        });
    };

    const handleSave = async () => {
        const updatedFormData = new FormData();
    updatedFormData.append('name', newData.name);
    updatedFormData.append('name_en', newData.name_en);
    updatedFormData.append('link', newData.link);
    updatedFormData.append('icon', editedImage);
        axios.post(`${baseURL}/socialmedia/${editingField}`,updatedFormData,{ headers: {'Authorization': `Bearer ${token}`}})
        .then(response =>{window.location.reload();})
        .catch(error => console.error('Error fetching images:', error));
     
        // setAlert({ show: true, message: 'Contact data updated successfully', variant: 'success' });
        // setEditingField('');
        // setNewData('');
        // setAlert({ show: true, message: error.message, variant: 'danger' });
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
          const reader = new FileReader();
          reader.onloadend = () => {
            setEditedImage( file );
          };
          reader.readAsDataURL(file);
        }
      };

    return (
        <Container className="my-5">
                   <center style={{ margin: '10px' }}>
        <Sidebar /><br /><br />
        <Button variant='primary' href='/dashboard'>Back To Dashboard</Button>
      </center>
            <h2 className="text-center mb-4">Manage Contact Information</h2>
            {alert.show && <Alert variant={alert.variant} onClose={() => setAlert({ show: false })} dismissible>{alert.message}</Alert>}
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>name</th>
                        <th>name_en</th>
                        <th>link</th>
                        <th>icon</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {contactData.length>0 && contactData.map(contact => (
                        <tr key={contact.id}>
                            <td>
                                {editingField === contact.id ? (
                                        <input type="text" value={newData.name} name='name' onChange={handleChange} />
                                    ) : (
                                        contact.name
                                    )}
                            </td>
                            <td>
                                {editingField === contact.id ? (
                                        <input type="text" value={newData.name_en} name='name_en' onChange={handleChange} />
                                    ) : (
                                        contact.name_en
                                    )}
                            </td>
                            <td>
                                {editingField === contact.id ? (
                                    <input type="text" value={newData.link} name='link' onChange={handleChange} />
                                ) : (
                                    contact.link
                                )}
                            </td>
                            <td>
                                {editingField === contact.id ? (
                                    <input
                                    type="file"
                                    name="icon"
                                    onChange={handleFileChange}
                                    />
                                ) : (
                                    <img src={imgSrc+'/'+contact.icon} alt={contact.name} style={{ width: '50px' }} />
                                )}
                            </td>
                            <td>
                                {editingField === contact.id ? (
                                    <Button variant="success" onClick={handleSave}>Save</Button>
                                ) : (
                                    <Button variant="primary" onClick={() => handleEdit(contact)}>Edit</Button>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </Container>
    );
};

export default ContactUSManage;
