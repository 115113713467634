// App.js
import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import Navy from './Components/Navbar/Nav';
import Home from './Components/Home/Home';
import Footer from './Components/Navbar/Footer';
import Head from './Components/Navbar/Head';
import About from './Components/About/About';
import Contact from './Components/Contact/Contact';
import Feedback from './Components/Feedback/Feedback';
import ProductSlider from './Dash/Sidebar/Test';
import ProductList from './Components/Products/ProductList';
import ProductDetails from './Components/Products/ProductDetails';
import CategoryCard from './Components/Products/ProductsCateg';
import Dashboard from './Dash/Dashboard/Dashboard';
import AddReview from './Dash/Dashboard/Reviews/AddReview';
import TheReviews from './Dash/Dashboard/Reviews/TheReviews';
import AddCategory from './Dash/Dashboard/Categories/AddCategory';
import ContactUSMessage from './Dash/Dashboard/ContactUSMessage/ContactUSMessage';
import Statistics from './Dash/Dashboard/Statistics/Statistics';
import SubscribePhone from './Dash/Dashboard/SubscribePhone/SubscribePhone';
import SubscribeEmail from './Dash/Dashboard/SubscribeEmail/SubscribeEmail';
import TheCategories from './Dash/Dashboard/Categories/TheCategories';
import AddProducts from './Dash/Dashboard/Products/AddProducts';
import TheProducts from './Dash/Dashboard/Products/TheProducts';
import TheCommonProducts from './Dash/Dashboard/CommonProducts/TheCommonProducts';
import CommonPage from './Dash/Dashboard/CommonProducts/CommonPage';
import RealFB from './Dash/Dashboard/RealFb/RealFB';
import ContactUSManage from './Components/Contact/ContactusManage';
import TheMails from './Dash/Dashboard/maillist/TheMails';
import { PublicProvider } from './context/public-context';
import AboutUsSection from './Dash/Dashboard/aboutUs/about_us_section';
import OurGoalSection from './Dash/Dashboard/aboutUs/our_goal_section';
import WebsiteSlider from './Dash/slider/website_slider';
import AboutSlider from './Dash/slider/about_slider';
import WhoIsHookSection from './Dash/Dashboard/aboutUs/who_is_hook_section';
import YoutubeVideo from './Dash/Dashboard/youtube_videos/youtub_video';
import AddNewLink from './Dash/Dashboard/youtube_videos/addNewLinkVideo';
import UpdateLink from './Dash/Dashboard/youtube_videos/editNewLinkVideo';
import TheProductImages from './Dash/Dashboard/Products/images';
import WhereToBuy from './Components/whereToBuy/whereToBuy';
import Countries from './Dash/Dashboard/countries/countries';
import AddCountry from './Dash/Dashboard/countries/AddCountry';
import TheProductInfo from './Dash/Dashboard/Products/info';
import Partners from './Dash/partners/partners';
import AddPartner from './Dash/partners/addPartner';
import PrivateRoute from './Components/protected/protects';



function App() {
  return (
    <PublicProvider>
      <BrowserRouter>
        <Head />
        <Navy />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="*" element={<Navigate to="/" />} />

          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/feedback" element={<Feedback />} />
          <Route path="/dash" element={<ProductSlider />} />
          <Route path="/products" element={<ProductList />} />
          <Route path="/products/:categoryId" element={<ProductList />} />
          <Route path="/products/:categoryId/:productId" element={<ProductDetails />} />
          <Route path="/cate" element={<CategoryCard />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/addreview" element={<AddReview />} />
          <Route path="/thereviews" element={<TheReviews />} />


          <Route path="/thecommonProducts" element={<TheCommonProducts />} />
          <Route path="/addCommonProducts" element={<CommonPage />} />


          <Route path="/AddNewLinkYoutubeVideo" element={<AddNewLink />} />
          <Route path="/UpdateLinkYoutubeVideo/:id" element={<UpdateLink />} />
          <Route path="/RealFeedback" element={<RealFB />} />
          <Route path="/mailssub" element={<TheMails />} />
          <Route path="/whereToBuy" element={<WhereToBuy />} />


        
        {/* ========================== Dashboard=========================================== */}
          <Route element={<PrivateRoute />}>
            {/* ================== Products =================== */}
            <Route path="/theproducts" element={<TheProducts />} />
            <Route path="/addproduct" element={<AddProducts />} />
            <Route path="/addproduct/images/:id" element={<TheProductImages />} />
            <Route path="/addproduct/info/:id" element={<TheProductInfo />} />
            {/* ================== Categories =================== */}
            <Route path="/addcategory" element={<AddCategory />} />
            <Route path="/thecategories" element={<TheCategories />} />
            {/* ================== Silders =================== */}
            <Route path="/websiteSlider" element={<WebsiteSlider />} />
            <Route path="/aboutSlider" element={<AboutSlider />} />
            {/* ================== Sections =================== */}
            <Route path="/aboutSection" element={<AboutUsSection />} />
            <Route path="/goalSection" element={<OurGoalSection />} />
            <Route path="/hookSection" element={<WhoIsHookSection />} />
            {/* ================== Countries  =================== */}
            <Route path="/countries" element={<Countries />} />
            <Route path="/addCountry" element={<AddCountry />} />
            {/* ================== Partners  =================== */}
            <Route path="/partners" element={<Partners />} />
            <Route path="/addPartner" element={<AddPartner />} />
            {/* ================== Youtube Videos =================== */}
            <Route path="/youtubeVideos" element={<YoutubeVideo />} />
            {/* ================== Social Media =================== */}
            <Route path="/contactmanage" element={<ContactUSManage />} />
            {/* ================== contact us =================== */}
            <Route path="/contactus" element={<ContactUSMessage />} />
            <Route path="/Statistics" element={<Statistics/>} />
            <Route path="/subscribe-phone" element={<SubscribePhone/>} />
            <Route path="/subscribe-email" element={<SubscribeEmail/>} />
          </Route>

        </Routes>
        <Footer />
      </BrowserRouter>
    </PublicProvider>
  );
}

export default App;
