import React, { useContext, useEffect, useState } from 'react';
import { Alert, Button, Container } from 'react-bootstrap';
import Sidebar from '../../Sidebar/Sidebar';
import { useNavigate } from 'react-router-dom';
import { PublicContext } from '../../../context/public-context';
import axios from 'axios';

const TheMessages = () => {
  const [contactusmessages, setContactUsMessages] = useState([]);
  const [alert, setAlert] = useState({ show: false, variant: '', message: '' });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const { baseURL, token } = useContext(PublicContext);
  const navigate = useNavigate();

  const fetchMessages = (page = 1) => {
    axios.get(`${baseURL}/contactusmessages?page=${page}`, { headers: { 'Authorization': `Bearer ${token}` } })
    .then(response => {
      setContactUsMessages(response.data.data?.data || []); // Fallback to an empty array if undefined
      setCurrentPage(response.data.data.current_page);
      setTotalPages(response.data.data.last_page);
    })
    .catch(error => {
      console.error('Error fetching messages:', error);
      setContactUsMessages([]); // Set to an empty array if there's an error
    });
  };

  useEffect(() => {
    fetchMessages();
  }, []);

  const handleDelete = (id) => {
    axios.get(`${baseURL}/contactusmessages/destroy/${id}`, { headers: { 'Authorization': `Bearer ${token}` } })
      .then(() => fetchMessages(currentPage))
      .catch(error => console.log(error));
  };

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      fetchMessages(newPage);
    }
  };

  return (
    <Container style={{ marginBottom: '20px' }}>
      <center style={{ margin: '10px' }}>
        <Sidebar /><br /><br />
        <Button variant='primary' href='/dashboard'>Back To Dashboard</Button>
      </center>
      <h2 className="headline">View or Delete Feedback</h2>
      {alert.show && <Alert variant={alert.variant}>{alert.message}</Alert>}
      <table border="1" cellPadding="10" cellSpacing="0" style={{ width: '100%', borderCollapse: 'collapse', border: '1px solid black' }}>
        <thead>
          <tr>
            <th style={{ border: '1px solid black' }}>First Name</th>
            <th style={{ border: '1px solid black' }}>Last Name</th>
            <th style={{ border: '1px solid black' }}>Email</th>
            <th style={{ border: '1px solid black' }}>Phone</th>
            <th style={{ border: '1px solid black' }}>Message</th>
            <th style={{ border: '1px solid black' }}>Delete</th>
          </tr>
        </thead>
        <tbody>
          {contactusmessages.map(message => (
            <tr key={message.id}>
              <td style={{ border: '1px solid black' }}>{message.firstname}</td>
              <td style={{ border: '1px solid black' }}>{message.lastname}</td>
              <td style={{ border: '1px solid black' }}>{message.email}</td>
              <td style={{ border: '1px solid black' }}>{message.phone}</td>
              <td style={{ border: '1px solid black' }}>{message.message}</td>
              <td style={{ border: '1px solid black' }}>
                <Button variant="danger" onClick={() => handleDelete(message.id)}>Delete</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div style={{ marginTop: '20px' }}>
        <Button disabled={currentPage === 1} onClick={() => handlePageChange(currentPage - 1)}>Previous</Button>
        <span style={{ margin: '0 10px' }}>Page {currentPage} of {totalPages}</span>
        <Button disabled={currentPage === totalPages} onClick={() => handlePageChange(currentPage + 1)}>Next</Button>
      </div>
    </Container>
  );
};

export default TheMessages;
