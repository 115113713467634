import React, { useContext, useEffect, useState } from "react"
import './Footer.css';
import pho from '../../assets/imgs/h-colored.png'
import { useTranslation } from 'react-i18next'
import axios from "axios";
import { PublicContext } from "../../context/public-context";
const Footer = () => {
    const [contactData, setContactData] = useState({});
    let { baseURL} = useContext(PublicContext);

    const { t,i18n } = useTranslation()
    const fetchContactData = async () => {
        axios.get(`${baseURL}/socialmedia`)
        .then(response =>{setContactData(response.data.data);})
        .catch(error => console.error('Error fetching images:', error));
    };

    useEffect(() => {
        fetchContactData();
    }, []);
    return <footer className="page-footer font-small blue pt-4">
    <div className="container-fluid text-center text-md-left">
        <div className="row">
        <div className="col-md-4 mb-md-0 mb-3 ">
               <img src={pho} width={'250px'} className="fot"/>
                <h3 id="fotertitle" >{t('the_world')}</h3>
            </div>
        <div className="col-md-2 mb-md-0 mb-3">
                <h5 className="text-uppercase" style={{fontFamily:'secondaryfont'}}>{t('hook')}</h5>

                <ul className="list-unstyled linksfooter">
                    <li><a href="/">{t('home')}</a></li>
                    <li><a href="/about">{t('about_us')}</a></li>
                    <li><a href="/contact">{t('contact_us')}</a></li>
                    <li><a href="/">{t('products')}</a></li>
                    <li><a href="/whereToBuy">{t('WHERE TO BUY')}</a></li>
                </ul>
            </div>

            <div className="col-md-3 mb-md-0 mb-3">
                {/* <h5 className="text-uppercase">{t('social_media')}</h5>
                <ul className="list-unstyled linksfooter">
                     {contactData.length>0 &&contactData.map(contact=>(
                        <li><a href={contact.link}>{ i18n.language === 'ar' ? contact.name : contact.name_en}</a></li>
                    ))} 
                     <li><a href="/">{t('instagram')}</a></li>
                    <li><a href="/">{t('tiktok')}</a></li>
                    <li><a href="/">{t('whatsapp')}</a></li> 
                </ul> */}
            </div>

            <div className="col-md-3 mb-md-0 mb-3">
                <h4 className="text-uppercase" style={{color:'#FFC55A'}}>{t('be_the')}</h4>
                <h6>{t('get_the')}</h6>
<form className="subscribe-form" >
      <div className="custom-input-group">
        <input
          type="email"
          className="custom-input"
          id="customEmail"
          name="customEmail"
          placeholder={t('your_phone')}
          autoComplete="off"
        />
        <input className="custom-submit-button" value={t('subscribe')} type="submit" />
      </div>
    </form> 
            </div>

      
        </div>
    </div>

    <div className="footer-copyright text-center py-3">
    </div>

</footer>}

export default Footer;