import React, { useContext, useEffect, useState } from 'react';
import { Container, Row ,Carousel, Col, NavDropdown} from 'react-bootstrap';
import Numberss from '../About/Numberss';
import ProductSlider from '../../Dash/Sidebar/Test';
import './Home.css';
import WhySection from '../Why/WhySection';
import Mail from '../maillist/Mail';
import { PublicContext } from '../../context/public-context';
import axios from 'axios';
import VideoSection from './videoSectio';
import SocialMediaSection from './socialMediaSection';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import WhereToBuy from '../whereToBuy/whereToBuy';

const Home = () => {

    const { t, i18n } = useTranslation();
    const [slider, setSlider] = useState([]);
    let { baseURL,imgSrc} = useContext(PublicContext);
    const [categories, setCategories] = useState([]);
    const navigate=useNavigate();
    const handleImageError = (e) => {
      e.target.src = `${process.env.PUBLIC_URL}/hook.jpg`;
    };
    const fetchCategories = () => {
      axios.get(`${baseURL}/categories`)
      .then(response =>setCategories(response.data.data) )
      .catch(error => console.error('Error fetching categories:', error));
    };
  
    useEffect(() => {
      fetchCategories();
    }, []);

    useEffect(() => {
        getSlider();
    }, []);
    const getSlider = async () => {
        axios.get(`${baseURL}/artmin/websiteSlider`)
        .then((response) => setSlider(response.data.data))
        .catch((error)=>console.log(error)) 
    };


    return (
        <div className='home'>
        <div className='sectiononehome'>
            {slider.length>0 && <Carousel>
                {slider.map((slider)=>(
                    <Carousel.Item >
                         {slider.is_image ==1 ? (
                      <img src={imgSrc+'/'+slider.img} alt={slider.name} className="d-block w-100 carousel-img" />
                      ) : (
                          <video width="20" height="20" controls  className="d-block w-100 carousel-img">
                          <source src={imgSrc+'/'+slider.video} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                    )}
                        {/* <img className="d-block w-100 carousel-img" src={imgSrc+'/'+slider.img} alt="slider" /> */}
                    </Carousel.Item>
                ))}
            </Carousel>}
            
        </div>
        <div className='sectiontwohome' style={{backgroundColor:'#F3F3F3'}}>
            <Container>
                <Row>
                    {/* <Numberss/> */}
                    <div className="container-xxl py-5">
      <Container>
        <Row className="g-5 align-items-center">
          <div lg={6}>
            <h6 className="section-title text-start text-primary text-uppercase">{t('Our Common Categories')}</h6>
            <h1 className="mb-4">
              {t('at')} <span className="text-primary text-uppercase">{t('hook')}</span>
            </h1>
            <Row>
                {categories.slice(0, 4).map((category, index) => (
                  <Col sm={6} md={3} key={index} className="g-4">
                    <NavDropdown.Item href={`/products/${category.id}`}>
                      <div className="navprod1 d-flex justify-content-center align-items-center">
                        <div className="navprodimg">
                          <img src={imgSrc+'/'+category.img} alt={category.name} className='rounded-2' onError={handleImageError} style={{height:'100px'}}/>
                        </div>
                        <div className="mx-1 ">
                          <h4 className='fs-4'> {i18n.language === 'ar' ? category.name : category.name_en}</h4>
                        </div>
                      </div>
                    </NavDropdown.Item>
                  </Col>
                ))}
                </Row>
          </div>
    
        </Row>
      </Container>
    </div>
                </Row>
            </Container>
        </div>
        <div className='sectionthreehome'>
            <VideoSection/>
            {/* <ProductSlider/> */}
        </div>
        <div className='sectionfourhome'>
            <WhySection/>
        </div>
        <div className='sectionfifthhome'>
            <Mail/>
        </div>
        <div className="row">
            <SocialMediaSection/>
        </div>
        <WhereToBuy/>

        </div>
    );
}

export default Home;
