import React, { useContext, useEffect, useState } from 'react';
import { Alert, Button, Container } from 'react-bootstrap';
import Sidebar from '../../Sidebar/Sidebar';
import { useNavigate } from 'react-router-dom';
import { PublicContext } from '../../../context/public-context';
import axios from 'axios';

const TheCategories = () => {
  const [categories, setCategories] = useState([]);
  const [editingCategory, setEditingCategory] = useState(null);
  const [formData, setFormData] = useState({ name: '', image: null });
  const [alert, setAlert] = useState({ show: false, variant: '', message: '' });

  let { baseURL,imgSrc,token} = useContext(PublicContext);
  const navigate=useNavigate();

 

  const fetchCategories = () => {
    axios.get(`${baseURL}/categories`,formData)
    .then(response =>setCategories(response.data.data) )
    .catch(error => console.error('Error fetching categories:', error));
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const handleEdit = (category) => {
    console.log(category);
    setEditingCategory(category.id);
    setFormData(category);
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, image: e.target.files[0] });
  };

  const handleSave = (id) => {
    if (!formData.image) {
      setAlert({ show: true, variant: 'danger', message: 'Please upload an image' });
      return;
    }
    const updatedFormData = new FormData();
    updatedFormData.append('name', formData.name);
    updatedFormData.append('name_en', formData.name_en);
    updatedFormData.append('image', formData.image);
    axios.post(`${baseURL}/categories/${id}`,updatedFormData,{ headers: {'Authorization': `Bearer ${token}`}})
    .then((response) => {window.location.reload()})
    .catch((error)=>console.log(error)) 
  };

  const handleDelete = (id) => {
    axios.get(`${baseURL}/categories/delete/${id}`,{ headers: {'Authorization': `Bearer ${token}`}})
    .then((response) => {setCategories(prev=>prev.filter(video => video.id !== id))})
    .catch((error)=>console.log(error)) 
  };

  return (
    <Container style={{marginBottom:'20px'}}>
      <center style={{margin:'10px'}}>
        <Sidebar /><br/><br/>
        <Button variant='primary' href='/dashboard'>Back To Dashboard</Button>
      </center>
      <h2 className="headline">Edit or Delete Feedback</h2>
      {alert.show && <Alert variant={alert.variant}>{alert.message}</Alert>}
      <table border="1" cellPadding="10" cellSpacing="0" style={{ width: '100%', borderCollapse: 'collapse', border: '1px solid black' }}>
        <thead>
          <tr>
            <th style={{ border: '1px solid black' }}>Category Name</th>
            <th style={{ border: '1px solid black' }}>Name IN English</th>
            <th style={{ border: '1px solid black' }}>Category Image</th>
            <th style={{ border: '1px solid black' }}>Edit</th>
            <th style={{ border: '1px solid black' }}>Delete</th>
          </tr>
        </thead>
        <tbody>
          {categories.map(category => (
            <tr key={category.id}>
              <td style={{ border: '1px solid black' }}>
                {editingCategory === category.id ? (
                  <input
                    type="text"
                    name='name'
                    value={formData.name}
                    onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                  />
                ) : (
                  category.name
                )}
              </td>
              <td style={{ border: '1px solid black' }}>
                {editingCategory === category.id ? (
                  <input
                    type="text"
                    name='name_en'
                    value={formData.name_en}
                    onChange={(e) => setFormData({ ...formData, name_en: e.target.value })}
                  />
                ) : (
                  category.name_en
                )}
              </td>
              <td style={{ border: '1px solid black' }}>
                {editingCategory === category.id ? (
                  <input
                    type="file"
                    onChange={handleFileChange}
                  />
                ) : (
                  <img src={imgSrc+'/'+category.img} alt={category.name} style={{ width: '100px', height: '100px' }} />
                )}
              </td>
              <td style={{ border: '1px solid black' }}>
                {editingCategory === category.id ? (
                  <Button onClick={() => handleSave(category.id)}>Save</Button>
                ) : (
                  <Button onClick={() => handleEdit(category)}>Edit</Button>
                )}
              </td>
              <td style={{ border: '1px solid black' }}>
                <Button variant="danger" onClick={() => handleDelete(category.id)}>Delete</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Container>
  );
};

export default TheCategories;
