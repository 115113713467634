import React, { useState, useEffect, useContext } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import Sidebar from '../Sidebar/Sidebar';
import { Link } from 'react-router-dom';
import Auth from './Auth';
import './Dashboard.css';
import { useTranslation } from 'react-i18next';
import { PublicContext } from '../../context/public-context';

const oneHour = 60 * 60 * 1000; // 1 hour in milliseconds
const Dashboard = () => {
  let { setToken} = useContext(PublicContext);

    const { t } = useTranslation();
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handleLogin = (token) => {
    const currentTime = new Date().getTime();
    sessionStorage.setItem('loginTime', currentTime);
    sessionStorage.setItem('authToken', token);
    setToken(token);
    setIsAuthenticated(true);
  };

  useEffect(() => {
    const checkAuth = () => {
      const loginTime = sessionStorage.getItem('loginTime');
      const token = sessionStorage.getItem('authToken');
      if (loginTime  && token) {
        const currentTime = new Date().getTime();
        if (currentTime - loginTime < oneHour) {
          setIsAuthenticated(true);
        } else {
          sessionStorage.removeItem('loginTime');
          sessionStorage.removeItem('authToken')
          setIsAuthenticated(false);
        }
      }
    };
    checkAuth();
    const interval = setInterval(checkAuth, oneHour); // Check every hour
    return () => clearInterval(interval);
  }, []);

  if (!isAuthenticated) {
    return <Auth onLogin={handleLogin} />;
  }

  return (
    <div className="dashboard">
     <center style={{margin:'10px'}}>
        <Sidebar />
      </center>
      <Container>
        <Row>
          <Col id='feddiv'>
            <h2
              className="fancy"
              id="dividerfont"
              style={{
                "--w": "50vw",
                "--c": "#003c43",
                "--b": "4px",
                "--g": "-5px",
                fontFamily: 'Knewave',
              }}
            >
              Dashboard
            </h2>
          </Col>
        </Row>
      </Container>
      <Container className="dashboard-content">
        <Row>
          <Col xs={12} md={4} className="mb-3">
            <div className="group-wrapper">
              <Card as={Link} to={'/addproduct'} className="dashboard-card">
                <Card.Body>
                  <Card.Title>Add Product</Card.Title>
                </Card.Body>
              </Card>
              <Card as={Link} to={'/theproducts'} className="dashboard-card">
                <Card.Body>
                  <Card.Title>Products</Card.Title>
                </Card.Body>
              </Card>
            </div>
          </Col>
          
          {/* <Col xs={12} md={4} className="mb-3">
            <div className="group-wrapper">
              <Card as={Link} to={'/addreview'} className="dashboard-card">
                <Card.Body>
                  <Card.Title>Add Review</Card.Title>
                </Card.Body>
              </Card>
              <Card as={Link} to={'/thereviews'} className="dashboard-card">
                <Card.Body>
                  <Card.Title>Reviews</Card.Title>
                </Card.Body>
              </Card>
            </div>
          </Col> */}
          <Col xs={12} md={4} className="mb-3">
            <div className="group-wrapper">
              <Card as={Link} to={'/addcategory'} className="dashboard-card">
                <Card.Body>
                  <Card.Title>Add Category</Card.Title>
                </Card.Body>
              </Card>
              <Card as={Link} to={'/thecategories'} className="dashboard-card">
                <Card.Body>
                  <Card.Title>Categories</Card.Title>
                </Card.Body>
              </Card>
            </div>
          </Col>
          <Col xs={12} md={4} className="mb-3">
            <div className="group-wrapper">
              <Card as={Link} to={'/websiteSlider'} className="dashboard-card">
                <Card.Body>
                  <Card.Title>{t('website_silder')} </Card.Title>
                </Card.Body>
              </Card>
              <Card as={Link} to={'/aboutSlider'} className="dashboard-card">
                <Card.Body>
                  <Card.Title>{t('about_silder')}</Card.Title>
                </Card.Body>
              </Card>

            </div>
          </Col>
        </Row>
        <Row>

          <Col xs={12} md={4} className="mb-3">
            <div className="group-wrapper">
            
              <Card as={Link} to={'/contactmanage'} className="dashboard-card">
                <Card.Body>
                  <Card.Title>Social Media and contact data </Card.Title>
                </Card.Body>
              </Card>
              <Card as={Link} to={'/youtubeVideos'} className="dashboard-card">
                <Card.Body>
                  <Card.Title>{t('Youtube Videos')}</Card.Title>
                </Card.Body>
              </Card>
            </div>
          </Col>
          {/* <Col xs={12} md={4} className="mb-3">
            <div className="group-wrapper">
              <Card as={Link} to={'/RealFeedback'} className="dashboard-card">
                <Card.Body>
                  <Card.Title>Real Feedback from clients "Form Site" </Card.Title>
                </Card.Body>
              </Card>
              <Card as={Link} to={'/mailssub'} className="dashboard-card">
                <Card.Body>
                  <Card.Title>Mail List Subscribe</Card.Title>
                </Card.Body>
              </Card>
            </div>
          </Col> */}
                    <Col xs={12} md={4} className="mb-3">
            <div className="group-wrapper">
              <Card as={Link} to={'/aboutSection'} className="dashboard-card">
                <Card.Body>
                  <Card.Title>{t('about_us_section')} </Card.Title>
                </Card.Body>
              </Card>
              <Card as={Link} to={'/goalSection'} className="dashboard-card">
                <Card.Body>
                  <Card.Title>{t('our_goal_section')}</Card.Title>
                </Card.Body>
              </Card>
              
            </div>
          </Col>
          <Col>
          <div className="group-wrapper">

            <Card as={Link} to={'/hookSection'} className="dashboard-card">
              <Card.Body>
                <Card.Title>{t('who_is_hook_section')}</Card.Title>
              </Card.Body>
            </Card>
          </div>
          </Col>
        </Row>
        <Row>
        <Col xs={12} md={4} className="mb-3">
            <div className="group-wrapper">
            
              <Card as={Link} to={'/addPartner'} className="dashboard-card">
                <Card.Body>
                  <Card.Title> Add Partners </Card.Title>
                </Card.Body>
              </Card>
              <Card as={Link} to={'/partners'} className="dashboard-card">
                <Card.Body>
                  <Card.Title>Partners</Card.Title>
                </Card.Body>
              </Card>
            </div>
          </Col>
        <Col xs={12} md={4} className="mb-3">
            <div className="group-wrapper">
            
              <Card as={Link} to={'/addCountry'} className="dashboard-card">
                <Card.Body>
                  <Card.Title> Add Country </Card.Title>
                </Card.Body>
              </Card>
              <Card as={Link} to={'/countries'} className="dashboard-card">
                <Card.Body>
                  <Card.Title>Countries</Card.Title>
                </Card.Body>
              </Card>
            </div>
          </Col>
        <Col xs={12} md={4} className="mb-3">
            <div className="group-wrapper">
            
            
              <Card as={Link} to={'/statistics'} className="dashboard-card">
                <Card.Body>
                  <Card.Title>Statistics</Card.Title>
                </Card.Body>
              </Card>
              <Card as={Link} to={'/contactus'} className="dashboard-card">
                <Card.Body>
                  <Card.Title>contact us messages</Card.Title>
                </Card.Body>
              </Card>
            </div>
          </Col>
          <Col xs={12} md={4} className="mb-3">
            <div className="group-wrapper">
            
            
              <Card as={Link} to={'/subscribe-phone'} className="dashboard-card">
                <Card.Body>
                  <Card.Title>Subscribe Phone</Card.Title>
                </Card.Body>
              </Card>
              <Card as={Link} to={'/subscribe-email'} className="dashboard-card">
                <Card.Body>
                  <Card.Title>Subscribe Email</Card.Title>
                </Card.Body>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Dashboard;
