import React, { createContext, useState} from 'react';

export const PublicContext = createContext();
export const PublicProvider = ({ children }) => {

    const baseURL='https://hook.mhabashy.com/api';
    const imgSrc='https://hook.mhabashy.com/uploads';

    const [token, setToken] = useState(sessionStorage.getItem('authToken'))




    return (
        <PublicContext.Provider value={{baseURL,imgSrc,token,setToken}}>
            {children}
        </PublicContext.Provider>
    );
};
