import React, { useState, useEffect, useContext } from 'react';
import { Button, Alert } from 'react-bootstrap';
import Sidebar from '../../Sidebar/Sidebar';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { PublicContext } from '../../../context/public-context';

const AddProducts = () => {
    const [loading, setLoading] = useState(false);

    const [formData, setFormData] = useState({
        name: '',
        image: null,
        description: '',
        category: '',
        price: ''
    });
    const [categories, setCategories] = useState([]);
    const [alert, setAlert] = useState({ show: false, variant: '', message: '' });

    let { baseURL,token} = useContext(PublicContext);
    const navigate=useNavigate();
  
   
  
    const fetchCategories = () => {
      axios.get(`${baseURL}/categories`,formData)
      .then(response =>setCategories(response.data.data) )
      .catch(error => console.error('Error fetching categories:', error));
    };
  
    useEffect(() => {
      fetchCategories();
    }, []);



    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleFileChange = (e) => {
        const { name } = e.target;
        if(name==='pdf'){
            setFormData({ ...formData, pdf: e.target.files[0] });
        }else{
            setFormData({ ...formData, video: e.target.files[0] });
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!formData.category_id) {
            setAlert({ show: true, variant: 'danger', message: 'Please select a valid category' });
            return;
        }
        if (loading) return; 
        setLoading(true);
        const updatedFormData = new FormData();
        updatedFormData.append('name', formData.name);
        updatedFormData.append('name_en', formData.name_en);
        updatedFormData.append('description', formData.description);
        updatedFormData.append('description_en', formData.description_en);
        updatedFormData.append('category_id', formData.category_id);
        if(formData.video)
            updatedFormData.append('video', formData.video);
        if(formData.pdf)
            updatedFormData.append('pdf', formData.pdf);
        axios.post(`${baseURL}/products`,updatedFormData , { headers: {'Authorization': `Bearer ${token}`}})
        .then((response) => { navigate(`/addproduct/images/${response.data.data.id}`);  setLoading(false);})
        .catch((error)=>{console.log(error); setLoading(false);})

        
    };

    return (
        <div style={{ marginBottom: '10px' }}>
            <center style={{ margin: '10px' }}>
                {/* <Sidebar /><br /><br /> */}
                <Button variant='primary' href='/dashboard'>Back To Dashboard</Button>
            </center>
            {alert.show && <Alert variant={alert.variant}>{alert.message}</Alert>}
            <form className="register-form" id='elfed' onSubmit={handleSubmit}>
                <p className="register-title">Add Product</p>
                <p className="register-message">
                    Fill data to add the product
                </p>
                <label className="register-label">
                    <input
                        className="register-input"
                        type="text"
                        name="name"
                        placeholder=""
                        value={formData.name}
                        onChange={handleChange}
                        required
                    />
                    <span>Product Name</span>
                </label>
                <label className="register-label">
                    <input
                        className="register-input"
                        type="text"
                        name="name_en"
                        placeholder=""
                        value={formData.name_en}
                        onChange={handleChange}
                        required
                    />
                    <span>English Product Name</span>
                </label>
                <label className="register-label">
                    <textarea
                        className="register-input"
                        name="description"
                        placeholder=""
                        value={formData.description}
                        onChange={handleChange}
                        required
                    />
                    <span>Product Description</span>
                </label>
                <label className="register-label">
                    <textarea
                        className="register-input"
                        name="description_en"
                        placeholder=""
                        value={formData.description_en}
                        onChange={handleChange}
                        required
                    />
                    <span>English Product Description</span>
                </label>
                <label className="register-label">
                    <select
                        className="register-input"
                        name="category_id"
                        value={formData.category_id}
                        onChange={handleChange}
                        required
                    >
                        <option value="">Select Category</option>
                        {categories.map(category => (
                            <option key={category.id} value={category.id}>
                                {category.name}
                            </option>
                        ))}
                    </select>
                    <span>Category</span>
                </label>
                <label className="register-label">
                    <input
                        className="register-input"
                        type="file"
                        name="video"
                        onChange={handleFileChange}
                    />
                    <span>Upload video</span>
                </label>
                <label className="register-label">
                    <input
                        className="register-input"
                        type="file"
                        name="pdf"
                        onChange={handleFileChange}
                    />
                    <span>Upload pdf</span>
                </label>
                <button className="register-submit" type="submit">{loading ? 'Loading...':'Submit'}</button>
            </form>
        </div>
    );
}

export default AddProducts;
