import React, { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { PublicContext } from "../../context/public-context";

const PrivateRoute = () => {
  const {token} = useContext(PublicContext);
  if (!token) return <Navigate to="/dashboard" />;
  return <Outlet />;
};

export default PrivateRoute;