import React, { useState, useEffect, useContext } from 'react';
import { Alert, Button } from 'react-bootstrap';
import Sidebar from '../../Sidebar/Sidebar';
import { useNavigate } from 'react-router-dom';
import { PublicContext } from '../../../context/public-context';
import axios from 'axios';

const TheProducts = () => {
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [products, setProducts] = useState([]);
    const [editingProductId, setEditingProductId] = useState(null);
    const [editedProduct, setEditedProduct] = useState({});
    const [alert, setAlert] = useState({ show: false, variant: '', message: '' });
    let { baseURL,token,imgSrc} = useContext(PublicContext);
    const navigate=useNavigate();
    useEffect(() => {
        fetchProducts();
        fetchCategories();
    }, []);

    const fetchProducts = () => {
        axios.get(`${baseURL}/products`)
        .then(response =>setProducts(response.data.data) )
        .catch(error => console.error('Error fetching products:', error));
    };
    
    const fetchCategories = () => {
        axios.get(`${baseURL}/categories`)
        .then(response =>setCategories(response.data.data) )
        .catch(error => console.error('Error fetching categories:', error));
    };


    const handleCategoryChange = (e) => {
        setSelectedCategory(e.target.value);
        fetchProductsByCate(e.target.value);
    };

    const fetchProductsByCate = (id) => {
        axios.get(`${baseURL}/category/${id}`)
        .then(response =>setProducts(response.data.data) )
        .catch(error => console.error('Error fetching products:', error));
    };

    const handleEditClick = (productId, productData) => {
        setEditingProductId(productId);
        setEditedProduct(productData);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditedProduct({ ...editedProduct, [name]: value });
    };

    const handleFileChange = (e) => {
        setEditedProduct({ ...editedProduct, video: e.target.files[0] });
    };

    const handleSaveClick = (id) => {
        const updatedFormData = new FormData();
        updatedFormData.append('name', editedProduct.name);
        updatedFormData.append('name_en', editedProduct.name_en);
        updatedFormData.append('description', editedProduct.description);
        updatedFormData.append('description_en', editedProduct.description_en);
        updatedFormData.append('category_id', editedProduct.category_id);
        if(editedProduct.video)
        updatedFormData.append('video', editedProduct.video);
        axios.post(`${baseURL}/products/${id}`,updatedFormData,{ headers: {'Authorization': `Bearer ${token}`}})
        .then((response) => {window.location.reload()})
        .catch((error)=>console.log(error))
    };

    const handleEditImages = (id) => {
      navigate(`/addproduct/images/${id}`)
    };
    const handleEditInfo = (id) => {
      navigate(`/addproduct/info/${id}`)
    };

    const handleDeleteClick = (id) => {
        axios.get(`${baseURL}/products/delete/${id}`,{ headers: {'Authorization': `Bearer ${token}`}})
        .then((response) => {setProducts(prev=>prev.filter(video => video.id !== id))})
        .catch((error)=>console.log(error))
    };

    return (
        <div>
            <div className='container' style={{ marginBottom: '20px' }}>
                <center style={{ margin: '10px' }}>
                    {/* <Sidebar /><br /><br /> */}
                    <Button variant='primary' href='/dashboard'>Back To Dashboard</Button>
                </center>
                <h2 className="headline">Edit or Delete Products</h2>
                {alert.show && <Alert variant={alert.variant}>{alert.message}</Alert>}
                <select value={selectedCategory} onChange={handleCategoryChange}>
                    <option value="">Select Category</option>
                    {categories.map(category => (
                        <option key={category.id} value={category.id}>{category.name}</option>
                    ))}
                </select>
                <table border="1" cellPadding="10" cellSpacing="0" style={{ width: '100%', borderCollapse: 'collapse', border: '1px solid black' }}>
                    <thead>
                        <tr>
                            <th style={{ border: '1px solid black' }}>Name</th>
                            <th style={{ border: '1px solid black' }}> Name_en</th>
                            <th style={{ border: '1px solid black' }}>Description</th>
                            <th style={{ border: '1px solid black' }}>Description_en</th>
                            <th style={{ border: '1px solid black' }}>Category</th>
                            <th style={{ border: '1px solid black' }}>Video</th>
                            <th style={{ border: '1px solid black' }}>Edit</th>
                            <th style={{ border: '1px solid black' }}>Images</th>
                            <th style={{ border: '1px solid black' }}>Info</th>
                            <th style={{ border: '1px solid black' }}>Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        {products.map(product => (
                            <tr key={product.id}>
                                <td style={{ border: '1px solid black' }}>
                                    {editingProductId === product.id ? (
                                        <input
                                            type="text"
                                            name="name"
                                            value={editedProduct.name}
                                            onChange={handleInputChange}
                                        />
                                    ) : (
                                        product.name
                                    )}
                                </td>
                                <td style={{ border: '1px solid black' }}>
                                    {editingProductId === product.id ? (
                                        <input
                                            type="text"
                                            name="name_en"
                                            value={editedProduct.name_en}
                                            onChange={handleInputChange}
                                        />
                                    ) : (
                                        product.name_en
                                    )}
                                </td>
                                <td style={{ border: '1px solid black' }}>
                                    {editingProductId === product.id ? (
                                        <textarea
                                            name="description"
                                            value={editedProduct.description}
                                            onChange={handleInputChange}
                                        />
                                    ) : (
                                        product.description
                                    )}
                                </td>
                                <td style={{ border: '1px solid black' }}>
                                    {editingProductId === product.id ? (
                                        <textarea
                                            name="description_en"
                                            value={editedProduct.description_en}
                                            onChange={handleInputChange}
                                        />
                                    ) : (
                                        product.description_en
                                    )}
                                </td>
                                <td style={{ border: '1px solid black' }}>
                                    {editingProductId === product.id ? (
                                        
                                        <select value={selectedCategory} name='category_id' onChange={handleInputChange}>
                                            {categories.map(category => (
                                                <option key={category.id} value={category.id}>{category.name}</option>
                                            ))}
                                        </select>
                                    ) : (
                                        product.category.name
                                    )}
                                </td>
                                <td style={{ border: '1px solid black' }}>
                                    {editingProductId === product.id ? (
                                    <input
                                        type="file"
                                        onChange={handleFileChange}
                                    />
                                    ) : (
                                        <video width="20" height="20" controls className='video-dash'>
                                        <source src={imgSrc+'/'+product.video} type="video/mp4" />
                                        Your browser does not support the video tag.
                                      </video>
                                    )}
                                </td>                                
                                <td style={{ border: '1px solid black' }}>
                                    {editingProductId === product.id ? (
                                        <Button onClick={() => handleSaveClick(product.id)}>Save</Button>
                                    ) : (
                                        <Button onClick={() => handleEditClick(product.id, product)}>Edit</Button>
                                    )}
                                </td>


                                <td style={{ border: '1px solid black' }}>
                                    <Button onClick={() => handleEditImages(product.id)}>Images</Button>
                                </td>
                                <td style={{ border: '1px solid black' }}>
                                    <Button onClick={() => handleEditInfo(product.id)}>Info</Button>
                                </td>
                                <td style={{ border: '1px solid black' }}>
                                    <Button variant="danger" onClick={() => handleDeleteClick(product.id)}>Delete</Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default TheProducts;
