import React, { useState, useEffect, useContext } from 'react';
import { Container, Button, Alert, Table } from 'react-bootstrap';
import axios from 'axios';
import { PublicContext } from '../../../context/public-context';
import { useNavigate } from 'react-router-dom';

const YoutubeVideo = () => {
  const [videos, setVideos] = useState([]);
  let { baseURL,token} = useContext(PublicContext);
  const navigate=useNavigate();

  useEffect(() => {
    getVideos();
  }, []);

  const getVideos = async () => {
    axios.get(`${baseURL}/youtube-links`)
    .then((response) => setVideos(response.data.data))
    .catch((error)=>console.log(error)) 
  };

  const handleEditClick = (id) => {
     navigate(`/UpdateLinkYoutubeVideo/${id}`);
  };

 
  const handleDeleteClick = async (id) => {
     axios.get(`${baseURL}/youtube-links/delete/${id}`,{headers: {'Authorization': `Bearer ${token}`}})
     .then((response) => {setVideos(videos.filter(video => video.id !== id))})
     .catch((error)=>console.log(error)) 
  };

  const addNewVideo=()=>{
    navigate('/AddNewLinkYoutubeVideo');
  }

  return (
    <div>
      <Container style={{ marginBottom: '20px' }}>
        
        <center style={{ margin: '10px' }}>
          {/* <Sidebar /><br /><br /> */}
          <Button variant='primary' href='/dashboard'>Back To Dashboard</Button>
        </center>
        <h2 className="headline">Edit or Delete Website video</h2>
        <Button className='my-3' onClick={addNewVideo}> Add Video </Button>
        <Table bordered>
          <thead>
            <tr>
              <th className='col'>Video Id</th>
              <th className='col'>Description</th>
              <th className='col'>Description in English</th>
              <th className='col'>Actions</th>
            </tr>
          </thead>
          <tbody>
            {videos.length>0 && videos.map(video => (
              <tr key={video.id}>
                <td>{video.url}</td>
                <td>{video.description}</td>
                <td>{video.description_en}</td>
                <td>
                <div className="d-flex justify-content-center">
                      <Button onClick={() =>handleEditClick(video.id)}>Edit</Button>
                      <Button variant="danger" className='mx-2' onClick={() => handleDeleteClick(video.id)}>Delete</Button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Container>
    </div>
  );
}

export default YoutubeVideo;
