import React, { useState, useEffect, useContext, useRef } from "react";
import { useParams } from "react-router-dom";
import "./ProductDet.css"; // Import your CSS file for styling

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';

// import required modules
import { FreeMode, Navigation, Thumbs } from 'swiper/modules';
import axios from "axios";
import { PublicContext } from "../../context/public-context";
import { useTranslation } from "react-i18next";

import { Nav, Tab, Row, Col, Card, ListGroup } from 'react-bootstrap';

const ProductDetails = () => {
  let { baseURL, imgSrc } = useContext(PublicContext);
  const { i18n, t } = useTranslation();
  const { categoryId, productId } = useParams();
  const [product, setProduct] = useState(null);
  const [info, setInfo] = useState(null);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const [activeKey, setActiveKey] = useState('overview');
  const hasFetchedData = useRef(false);

  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const styles = {
    scrollToTop: {
      position: 'fixed',
      bottom: '50px',
      right: '50px',
      padding: '10px 20px',
      fontSize: '18px',
      borderRadius: '5px',
      backgroundColor: 'transparent',
      color: '#fff',
      border: 'none',
      cursor: 'pointer',
      zIndex: 1000,
      transition: 'opacity 0.3s ease-in-out',
    },
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);

  useEffect(() => {
    getProduct();
    fetchInfo();

    // Ensure getData is called only once
    if (!hasFetchedData.current) {
      getData();
      hasFetchedData.current = true;
    }
  }, [categoryId, productId]);

  const getProduct = () => {
    axios.get(`${baseURL}/products/${productId}`)
      .then((response) => setProduct(response.data.data))
      .catch((error) => console.log(error));
  };

  const fetchInfo = () => {
    axios.get(`${baseURL}/artmin/product_${productId}`)
      .then(response => setInfo(response.data.data))
      .catch(error => console.error('Error fetching info:', error));
  };

  const getData = () => {
    axios.get("https://ipapi.co/json")
      .then(res => {
        console.log(res);
        const data = {
          ip: res.data.ip,
          country: res.data.country_name,
          city: res.data.city,
          product_id: productId,
        };
        axios.post(`${baseURL}/product-visits`, data)
          .then(response => console.log(response))
          .catch(error => console.error('Error logging product visit:', error));
      })
      .catch(error => console.error('Error fetching IP info:', error));
  };

  return (
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-6 col-md-12 mb-4">
          <div className="product-image-container">
            {product?.images && <>
              <Swiper
                style={{
                  '--swiper-navigation-color': '#fff',
                  '--swiper-pagination-color': '#fff',
                }}
                spaceBetween={10}
                navigation={true}
                thumbs={{ swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null }}
                modules={[FreeMode, Navigation, Thumbs]}
                className="mySwiper2"
              >
                {product.images.map((image, index) => (
                  <SwiperSlide key={`1+${index}`}>
                    <img src={`${imgSrc}/${image.name}`} alt="product" />
                  </SwiperSlide>
                ))}
              </Swiper>

              <Swiper
                onSwiper={setThumbsSwiper}
                spaceBetween={10}
                slidesPerView={4}
                freeMode={true}
                watchSlidesProgress={true}
                modules={[FreeMode, Navigation, Thumbs]}
                className="mySwiper"
              >
                {product.images.map((image, index) => (
                  <SwiperSlide key={`1+${index}`}>
                    <img src={`${imgSrc}/${image.name}`} alt="product thumbnail" />
                  </SwiperSlide>
                ))}
              </Swiper>
            </>}
          </div>
        </div>
        <div className="col-lg-6 col-md-12">
          <div className="product-info-container">
            <div className="product-name mb-3">
              <span id="bol"> </span>
              {i18n.language === 'ar' ? product?.name : product?.name_en}
            </div>
            <div className="product-description mb-3">
              <span id="bol"></span>
              {i18n.language === 'ar' ? product?.description : product?.description_en}
            </div>
          </div>
        </div>
      </div>

      <Tab.Container activeKey={activeKey} onSelect={(k) => setActiveKey(k)}>
        <Row className="mb-3">
          <Nav variant="tabs" className="w-100">
            <Nav.Item className="flex-fill text-center">
              <Nav.Link eventKey="overview" className="custom-tab fs-4">Overview</Nav.Link>
            </Nav.Item>
            <Nav.Item className="flex-fill text-center">
              <Nav.Link eventKey="info" className="custom-tab fs-4">Info</Nav.Link>
            </Nav.Item>
            <Nav.Item className="flex-fill text-center">
              <Nav.Link eventKey="Downloads" className="custom-tab fs-4">Downloads</Nav.Link>
            </Nav.Item>
          </Nav>
        </Row>
        <Row>
          <Col>
            <Tab.Content>
              <Tab.Pane eventKey="overview">
                <div>
                  {product?.video && <video width="600" controls>
                    <source src={`${imgSrc}/${product.video}`} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>}
                </div>
                {
                  product?.images.map((product, index) => {
                    if (index % 2 === 0) {
                      // Even index: name on the left, image on the right
                      return (
                        <div key={index} className="row align-items-center justify-content-center">
                          <div className="col-lg-6 col-md-12 product-description">
                            {i18n.language === 'ar' ? product.description : product.description_en}
                          </div>
                          <div className="col-lg-6 col-md-12 image-desc-container">
                            <img src={`${imgSrc}/${product.name}`} alt={product.name} className="product-image img-fluid" />
                          </div>
                        </div>
                      );
                    } else {
                      // Odd index: image on the left, name on the right
                      return (
                        <div key={index} className="row align-items-center justify-content-center">
                          <div className="col-lg-6 col-md-12 image-desc-container">
                            <img src={`${imgSrc}/${product.name}`} alt={product.name} className="product-image img-fluid" />
                          </div>
                          <div className="col-lg-6 col-md-12 product-description">
                            {i18n.language === 'ar' ? product.description : product.description_en}
                          </div>
                        </div>
                      );
                    }
                  })
                }
                {isVisible && (
                  <button onClick={scrollToTop} style={styles.scrollToTop}>
                    <img src={`${process.env.PUBLIC_URL}/up.svg`} width={40} alt="" />
                  </button>
                )}
              </Tab.Pane>
              <Tab.Pane eventKey="info">
                {info && info.map((e) => (
                  <Card className="my-3" key={e.id}>
                    <Card.Header>{i18n.language === 'ar' ? e.name : e.name_en}</Card.Header>
                    <ListGroup variant="flush">
                      <ListGroup.Item>{i18n.language === 'ar' ? e.description : e.description_en}</ListGroup.Item>
                    </ListGroup>
                  </Card>
                ))}
              </Tab.Pane>
              <Tab.Pane eventKey="Downloads">
                <div className="d-flex justify-content-center my-5">
                  {product?.pdf ? (
                    <iframe src={imgSrc + '/' + product.pdf} width="70%" height="400px" />
                  ) : (
                    <h3>No Files To Show</h3>
                  )}
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </div>
  );
}

export default ProductDetails;
