// ProductCard.js
import React from "react";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import "./ProductCard.css";
import { useTranslation } from "react-i18next";
const ProductCard = ({ product }) => {
  const handleImageError = (e) => {
    e.target.src = `${process.env.PUBLIC_URL}/hook.jpg`; // Path to the default image
  };
    const { i18n} = useTranslation();
  return (
    <div className="product-card">
      <div className="product-card__image">
        <img src={`https://hook.mhabashy.com/uploads/${product.images[0]?.name}`} alt={product.name}  onError={handleImageError} />
      </div>
      <div className="product-card__content">
        <p className="product-card__title">{product.name}</p>
        <Button
          as={Link}
          to={`/products/${product.category_id}/${product.id}`}
          variant="primary"
          className="mt-2"
        >
          {i18n.language === 'ar' ? 'تفاصيل اكثر' : 'More Details'}
          
        </Button>
      </div>
    </div>
  );
};

export default ProductCard;
