import React, { useContext, useEffect, useState } from 'react';
import { Alert, Button, Container } from 'react-bootstrap';
import { PublicContext } from '../../../context/public-context';

import Sidebar from '../../Sidebar/Sidebar';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';

const SubscribeEmail = () => {
  const [subscribers, setSubscribers] = useState([]);
  const [alert, setAlert] = useState({ show: false, variant: '', message: '' });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const { baseURL, token } = useContext(PublicContext);
  const navigate = useNavigate();

  const fetchSubscribers = (page = 1) => {
    axios.get(`${baseURL}/subscribes/email?page=${page}`, { headers: { 'Authorization': `Bearer ${token}` } })
      .then(response => {
        setSubscribers(response.data.data);
        setCurrentPage(1); // Assuming pagination is not implemented in the API response.
        setTotalPages(1); // Set total pages to 1 if pagination is not supported.
      })
      .catch(error => console.error('Error fetching subscribers:', error));
  };

  useEffect(() => {
    fetchSubscribers();
  }, []);

  const handleDelete = (id) => {
    axios.get(`${baseURL}/subscribes/delete/${id}`, { headers: { 'Authorization': `Bearer ${token}` } })
      .then(() => fetchSubscribers(currentPage))
      .catch(error => console.log(error));
  };

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      fetchSubscribers(newPage);
    }
  };

  return (
    <Container style={{ marginBottom: '20px' }}>
      <center style={{ margin: '10px' }}>
        <Sidebar /><br /><br />
        <Button variant='primary' href='/dashboard'>Back To Dashboard</Button>
      </center>
      <h2 className="headline">View or Delete Email Subscribers</h2>
      {alert.show && <Alert variant={alert.variant}>{alert.message}</Alert>}
      <table border="1" cellPadding="10" cellSpacing="0" style={{ width: '100%', borderCollapse: 'collapse', border: '1px solid black' }}>
        <thead>
          <tr>
            <th style={{ border: '1px solid black' }}>Name</th>
            <th style={{ border: '1px solid black' }}>Type</th>
            <th style={{ border: '1px solid black' }}>Created At</th>
            <th style={{ border: '1px solid black' }}>Delete</th>
          </tr>
        </thead>
        <tbody>
          {subscribers.map(subscriber => (
            <tr key={subscriber.id}>
              <td style={{ border: '1px solid black' }}>{subscriber.name}</td>
              <td style={{ border: '1px solid black' }}>{subscriber.type}</td>
              <td style={{ border: '1px solid black' }}>{moment(subscriber.created_at).format('YYYY-MM-DD')}</td>
              <td style={{ border: '1px solid black' }}>
                <Button variant="danger" onClick={() => handleDelete(subscriber.id)}>Delete</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div style={{ marginTop: '20px' }}>
        <Button disabled={currentPage === 1} onClick={() => handlePageChange(currentPage - 1)}>Previous</Button>
        <span style={{ margin: '0 10px' }}>Page {currentPage} of {totalPages}</span>
        <Button disabled={currentPage === totalPages} onClick={() => handlePageChange(currentPage + 1)}>Next</Button>
      </div>
    </Container>
  );
};

export default SubscribeEmail;
