import React, { useContext, useEffect, useState } from 'react';
import { Alert, Button, Container, Form } from 'react-bootstrap';
import Sidebar from '../../Sidebar/Sidebar';
import {  useParams } from 'react-router-dom';
import { PublicContext } from '../../../context/public-context';
import axios from 'axios';

const TheProductInfo = () => {
  const { id } = useParams();
  const [info, setinfo] = useState([]);
  const [editingImageId, setEditingImageId] = useState(null);
  const [formData, setFormData] = useState({ name: '', image: null });
  const [alert, setAlert] = useState({ show: false, variant: '', message: '' });

  const [newImageData, setNewImageData] = useState({ description: '', description_en: '', image: null });


  let { baseURL,imgSrc,token} = useContext(PublicContext);


  

  const fetchInfo = () => {
    axios.get(`${baseURL}/artmin/product_${id}`)
    .then(response =>setinfo(response.data.data) )
    .catch(error => console.error('Error fetching infi:', error));
  };

  useEffect(() => {
    fetchInfo();
  }, []);

  const handleEdit = (category) => {
    setEditingImageId(category.id);
     setFormData(category);
  };

  // const handleFileChange = (e) => {
  //   setFormData({ ...formData, image: e.target.files[0] });
  // };

  const handleSave = (id) => {
    // console.log(formData);
    // if (!formData.image) {
    //   setAlert({ show: true, variant: 'danger', message: 'Please upload an image' });
    //   return;
    // }
    
    // const updatedFormData = new FormData();
    // updatedFormData.append('description', formData.description);
    // updatedFormData.append('description_en', formData.description_en);
    // updatedFormData.append('image', formData.image);
    axios.post(`${baseURL}/artmin/update/${id}`,formData,{ headers: {'Authorization': `Bearer ${token}`}})
    .then((response) => {window.location.reload();})
    .catch((error)=>console.log(error)) 
  };

  const handleDelete = (id) => {
    axios.get(`${baseURL}/artmin/delete/${id}`,{ headers: {'Authorization': `Bearer ${token}`}})
    .then((response) => {setinfo(prev=>prev.filter(video => video.id !== id))})
    .catch((error)=>console.log(error)) 
  };

  const handleNewFileChange = (e) => {
    setNewImageData({ ...newImageData, image: e.target.files[0] });
  };

  const handleAddNewImage = () => {
    // if (!newImageData.image) {
    //   setAlert({ show: true, variant: 'danger', message: 'Please upload an image' });
    //   return;
    // }
    // const newFormData = new FormData();
    // newFormData.append('description', newImageData.description);
    // newFormData.append('description_en', newImageData.description_en);
    // newFormData.append('image', newImageData.image);
    console.log(formData);
    axios.post(`${baseURL}/artmin/store`, {...newImageData,type: `product_${id}`},{ headers: {'Authorization': `Bearer ${token}`}})
      .then((response) => { window.location.reload(); })
      .catch((error) => console.log(error));
  };

  return (
    <Container style={{marginBottom:'20px'}}>
      <center style={{margin:'10px'}}>
        {/* <Sidebar /><br/><br/> */}
        <Button variant='primary' href='/dashboard'>Back To Dashboard</Button>
      </center>



      <h4 className='mt-5'>Add New Info</h4>
      <Form>
        <Form.Group controlId="formName">
          <Form.Label>Title</Form.Label>
          <Form.Control
            type="text"
            name="name"
            value={newImageData.name}
            onChange={(e) => setNewImageData({ ...newImageData, [e.target.name]: e.target.value })}
          />
        </Form.Group>
        <Form.Group controlId="formNameEn">
          <Form.Label>Title in English</Form.Label>
          <Form.Control
            type="text"
            name="name_en"
            value={newImageData.name_en}
            onChange={(e) => setNewImageData({ ...newImageData, [e.target.name]: e.target.value })}
          />
        </Form.Group>
        <Form.Group controlId="formDescription">
          <Form.Label>Description</Form.Label>
          <Form.Control
            type="text"
            name="description"
            value={newImageData.description}
            onChange={(e) => setNewImageData({ ...newImageData, [e.target.name]: e.target.value })}
          />
        </Form.Group>
        <Form.Group controlId="formDescriptionEn">
          <Form.Label>Description in English</Form.Label>
          <Form.Control
            type="text"
            name="description_en"
            value={newImageData.description_en}
            onChange={(e) => setNewImageData({ ...newImageData, [e.target.name]: e.target.value })}
          />
        </Form.Group>

        <Button variant="primary" className='my-2' onClick={handleAddNewImage}>
          Add Info
        </Button>
      </Form>



      <h2 className="headline">Edit or Delete Feedback</h2>
      {alert.show && <Alert variant={alert.variant}>{alert.message}</Alert>}
      <table border="1" cellPadding="10" cellSpacing="0" style={{ width: '100%', borderCollapse: 'collapse', border: '1px solid black' }}>
        <thead>
          <tr>
            <th style={{ border: '1px solid black' }}>Title</th>
            <th style={{ border: '1px solid black' }}>Title_en</th>
            <th style={{ border: '1px solid black' }}>Description</th>
            <th style={{ border: '1px solid black' }}>Description_en</th>
            <th style={{ border: '1px solid black' }}>Edit</th>
            <th style={{ border: '1px solid black' }}>Delete</th>
          </tr>
        </thead>
        <tbody>
          {info.map(category => (
            <tr key={category.id}>
              <td style={{ border: '1px solid black' }}>
                {editingImageId === category.id ? (
                  <input
                    type="text"
                    name='name'
                    value={formData.name}
                    onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })}
                  />
                ) : (
                  category.name
                )}
              </td>
              <td style={{ border: '1px solid black' }}>
                {editingImageId === category.id ? (
                  <input
                    type="text"
                    name='name_en'
                    value={formData.name_en}
                    onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })}
                  />
                ) : (
                  category.name_en
                )}
              </td>
              <td style={{ border: '1px solid black' }}>
                {editingImageId === category.id ? (
                  <input
                    type="text"
                    name='description'
                    value={formData.description}
                    onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })}
                  />
                ) : (
                  category.description
                )}
              </td>
              <td style={{ border: '1px solid black' }}>
                {editingImageId === category.id ? (
                  <input
                    type="text"
                    name='description_en'
                    value={formData.description_en}
                    onChange={(e) => setFormData({ ...formData, [e.target.name]: e.target.value })}
                  />
                ) : (
                  category.description_en
                )}
              </td>
              <td style={{ border: '1px solid black' }}>
                {editingImageId === category.id ? (
                  <Button onClick={() => handleSave(category.id)}>Save</Button>
                ) : (
                  <Button onClick={() => handleEdit(category)}>Edit</Button>
                )}
              </td>
              <td style={{ border: '1px solid black' }}>
                <Button variant="danger" onClick={() => handleDelete(category.id)}>Delete</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>








    





    </Container>
  );
};

export default TheProductInfo;
