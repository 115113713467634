import React, { useContext, useEffect, useState } from "react";
import "./About.css";

import { Carousel, Col, Container, Row } from "react-bootstrap";
import photo2 from "../../assets/imgs/229.jpg";
import Numberss from "./Numberss";
import { PublicContext } from "../../context/public-context";
import axios from "axios";
import { useTranslation } from 'react-i18next';
const About = () => {

  const { t, i18n } = useTranslation();
  let { baseURL,imgSrc} = useContext(PublicContext);
  const [aboutSection, setAboutSection] = useState({})
  const [goalSection, setGoalSection] = useState({})
  const [slider, setSlider] = useState([]);

  const getSlider = async () => {
      axios.get(`${baseURL}/artmin/slideraboutus`)
      .then((response) => setSlider(response.data.data))
      .catch((error)=>console.log(error)) 
  };
  useEffect(() => {
    getAboutSection();
    getGoalSection();
    getSlider();
   }, []);
  

  const getAboutSection=()=>{
    axios.get(`${baseURL}/static_page/1`,)
    .then((response) => setAboutSection(response.data.data))
    .catch((error)=>console.log(error)) 
  } 
  const getGoalSection=()=>{
    axios.get(`${baseURL}/static_page/2`)
    .then((response) => setGoalSection(response.data.data))
    .catch((error)=>console.log(error)) 
  } 




  return (
    <div className="about">
      <div className="aboutheader">
      {slider.length>0 && <Carousel>
                {slider.map((slider)=>(
                    <Carousel.Item >
                        <img className="d-block w-100 carousel-img" src={imgSrc+'/'+slider.img} alt="slider" />
                    </Carousel.Item>
                ))}
            </Carousel>}
      </div>
      <Row>
        <Col>
          <div className="anim">
            <h1>{t('Who_are_we')}</h1>

            <img
              src="https://i.pinimg.com/originals/50/78/a0/5078a05eb1b6847d93383eaa4c0ed500.gif"
              width={"350px"}
            ></img>
          </div>
        </Col>
      </Row>

      <div className="firstsectionabout">
        <Container>
          <Row className="align-items-center">
            <Col>
              <div className="headline-container">
                <h1 className="headline">{ i18n.language === 'ar' ? aboutSection?.name : aboutSection?.name_en}</h1>
                <p>{ i18n.language === 'ar' ? aboutSection?.description : aboutSection?.description_en}</p>
              </div>
            </Col>
            <Col>
              <img src={`https://hook.mhabashy.com/uploads/${aboutSection.img}`} id="imageabout"></img>
            </Col>  
          </Row>
        </Container>
      </div>
      {/* <Numberss/> */}
      
     <div className="secondsection">
        <Container>
            <Row className="align-items-center">
            <Col>
                    <img src={photo2} alt="image" id="secondimage"></img>
                </Col>
                <Col className="secondcontent ">
                <h1 className="headline">{ i18n.language === 'ar' ? goalSection?.name : goalSection?.name_en}</h1>
                <p>{ i18n.language === 'ar' ? goalSection?.description : goalSection?.description_en} </p>
                </Col>
               
            </Row>
        </Container>
     </div>
    </div>
  );
};

export default About;
