import React, { useContext, useEffect, useState } from 'react';
import { Container, Form, Button, Alert } from 'react-bootstrap';
import '../Auth.css';
import axios from 'axios';
import { PublicContext } from '../../../context/public-context';
import { useNavigate, useParams } from 'react-router-dom';

const UpdateLink = () => {
  const {id} = useParams();
  const [error, setError] = useState('');
  let { baseURL,token} = useContext(PublicContext);
  const navigate=useNavigate();
  const [video, setVideo] = useState('')
  useEffect(() => {
    getVideo();
  }, []);

  const getVideo = async () => {
    axios.get(`${baseURL}/youtube-links/${id}`,{headers: {'Authorization': `Bearer ${token}`}})
    .then((response) => setVideo(response.data.data))
    .catch((error)=>console.log(error)) 
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    axios.post(`${baseURL}/youtube-links/${id}`,video,{headers: {'Authorization': `Bearer ${token}`}})
    .then((response) => {
        navigate('/youtubeVideos');
    })
    .catch((error)=>console.log(error)) 
  };

  const handleChangeForm=(event)=>{
    const {name,value}=event.target
    setVideo({
        ...video,
        [name]:value
    })
  }

  return (
    <Container className="auth-container border rounded p-4 mt-5 mb-5">
      <h2 className="text-center mb-4">Login</h2>
      {error && <Alert variant="danger">{error}</Alert>}
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="formUsername">
          <Form.Label>Link</Form.Label>
          <Form.Control 
            type="text" 
            placeholder="Enter url" 
            name='url'
            defaultValue={video.url}
            onChange={handleChangeForm} 
          />
        </Form.Group>
        <Form.Group >
          <Form.Label>Description</Form.Label>
          <Form.Control 
            type="text" 
            placeholder="Enter description in arabic " 
            name='description'
            defaultValue={video.description}
            onChange={handleChangeForm} 
          />
        </Form.Group>
        <Form.Group >
          <Form.Label>Description in English</Form.Label>
          <Form.Control 
            type="text" 
            placeholder="Enter description in english " 
            name='description_en'
            onChange={handleChangeForm} 
            defaultValue={video.description_en}
          />
        </Form.Group>

        <Button variant="primary" type="submit" block>
          update
        </Button>
      </Form>
    </Container>
  );
};

export default UpdateLink;
